<div class="body">
  <mat-sidenav-container class="container" style="background-color: black">
    <mat-sidenav
      style="background-color: black; width: 320px"
      #sidenav
      mode="side"
      [(opened)]="opened">
      <mat-button-toggle-group
        [(ngModel)]="screenSize"
        aria-label="Screen Size"
        (change)="changeScreenSize()"
        appearance="legacy"
        class="toggle"
        style="background-color: #27272b"
      >
        <mat-button-toggle value="phone">Phone</mat-button-toggle>
        <mat-button-toggle value="tablet">Tablet</mat-button-toggle>
        <mat-button-toggle value="desktop">Desktop</mat-button-toggle>
      </mat-button-toggle-group>
      <br />
      <label style="text-align: left; margin: 1rem 1rem 0; opacity: 0.7; display: block;">Change Theme </label>
      <div class="pill-container">
        <div *ngFor="let theme of themeKeys">
          <app-pill [theme]="theme" [colors]="getColors(theme)" (click)="changeTheme({value: theme})" (colorChanged)="changeColors(theme, $event)"></app-pill>
        </div>
      </div>

      <h2 class="boldText">Edit Website Below</h2>
      <app-dynamic-form
        [questions]="questions"
        [additionalSections]="additionalSections"
        [sections]="sections"
        [addSectionSpots]="addSectionSpots"
        [addedQuestions]="addedQuestions"
        [theme]="selectedTheme"
        [isThemeCustomized]="isThemeCustomized"
        [themeColors]="themeColors"
        [saved]="saved"
        (uploadFinished)="changeImage($event)"
        (tabOpened)="scroll($event)"
        (sectionRemoved)="removeSectionElements($event)"
        (sectionAdded)="cloneSectionElements($event)"
        (saveDone)="saved = $event"
        (loadDone)="loadElements()"
        (formSubmitted)="uploadHTML($event)"
        (newSectionAdded)="initialize(true)"
        (newSectionRemoved)="initialize(true)"
      ></app-dynamic-form>
    </mat-sidenav>

    <mat-sidenav-content style="overflow: hidden">
      <button class="button" (click)="sidenav.toggle(); changeScreenSize()">
        <ng-container *ngIf="opened">
          <span class="material-icons" style="font-size: 20px">
            arrow_back
          </span>
        </ng-container>
        <ng-container *ngIf="!opened">
          <span class="material-icons" style="font-size: 20px">
            arrow_forward
          </span>
        </ng-container>
      </button>
      <iframe
        *ngIf="delay$ | async"
        [src]="srcLink"
        id="frame"
        (load)="initialize()"
      ></iframe>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
