import { Injectable } from '@angular/core';
import { QuestionBase } from './question-base';
import { TextboxQuestion } from './question-textbox';
import { TextareaQuestion } from './question-textarea';
import { ImageQuestion } from './question-image';
import { ActivatedRoute } from '@angular/router';
import { LinkQuestion } from './question-link';

@Injectable()
export class QuestionService {
  constructor(private route: ActivatedRoute) {}

  getQuestions(elements: NodeListOf<Element>) {
    let questions: QuestionBase<string>[] = [];
    elements.forEach((element, i) => {
      if (element.getAttribute('data-editor-type') === 'text') {
        let name = element.getAttribute('data-editor-field');
        let label = element.getAttribute('data-editor-label');
        let section = element.getAttribute('data-question-section') || '';
        let required =
          element.getAttribute('data-editor-field-required') === 'true';
        let clonableStart =
          element.getAttribute('data-editor-isClonable-start') === 'true';
        let currentValue = (<HTMLElement>element).innerText;
        if (name && label) {
          questions.push(
            new TextboxQuestion({
              key: name,
              label: label,
              required: required,
              value: currentValue,
              section: section,
              clonableStart: clonableStart,
              order: i,
            })
          );
        }
      } else if (element.getAttribute('data-editor-type') === 'longText') {
        let name = element.getAttribute('data-editor-field');
        let label = element.getAttribute('data-editor-label');
        let section = element.getAttribute('data-question-section') || '';
        let required =
          element.getAttribute('data-editor-field-required') === 'true';
        let clonableStart =
          element.getAttribute('data-editor-isClonable-start') === 'true';
        let currentValue = (<HTMLElement>element).innerText;
        if (name && label) {
          questions.push(
            new TextareaQuestion({
              key: name,
              label: label,
              required: required,
              value: currentValue,
              section: section,
              clonableStart: clonableStart,
              order: i,
            })
          );
        }
      } else if (element.getAttribute('data-editor-type') === 'file') {
        let name = element.getAttribute('data-editor-field');
        let label = element.getAttribute('data-editor-label');
        let section = element.getAttribute('data-question-section') || '';
        let value = element.getAttribute('src');
        let required =
          element.getAttribute('data-editor-field-required') === 'true';
        let clonableStart =
          element.getAttribute('data-editor-isClonable-start') === 'true';
        if (name && label) {
          questions.push(
            new ImageQuestion({
              key: name,
              label: label,
              required: required,
              section: section,
              clonableStart: clonableStart,
              order: i,
              value: `assets/${this.route.snapshot.paramMap.get('page')}/` + value,
            })
          );
        }
      } else if (element.getAttribute('data-editor-type') === 'link') {
        let name = element.getAttribute('data-editor-field');
        let label = element.getAttribute('data-editor-label');
        let section = element.getAttribute('data-question-section') || '';
        let required =
          element.getAttribute('data-editor-field-required') === 'true';
        let clonableStart =
          element.getAttribute('data-editor-isClonable-start') === 'true';
        let currentValue = element.getAttribute('href');
        if (name && label) {
          questions.push(
            new LinkQuestion({
              key: name,
              label: label,
              required: required,
              value: currentValue,
              section: section,
              clonableStart: clonableStart,
              order: i,
            })
          );
        }
      }
    });
    return questions.sort((a, b) => a.order - b.order);
  }
}
