import { HttpClient } from '@angular/common/http';
import {ChangeDetectorRef, Component, HostListener, OnInit, Renderer2} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {ActivatedRoute} from '@angular/router';
import {delay, Observable, of} from 'rxjs';
import { environment } from 'src/environments/environment';
import {QuestionBase} from '../shared/question-base';
import {QuestionService} from '../shared/question.service';

@Component({
  selector: 'app-customize',
  templateUrl: './customize.component.html',
  styleUrls: ['./customize.component.css'],
  providers: [QuestionService],
})
export class CustomizeComponent implements OnInit {
  questions!: QuestionBase<string>[];
  addedQuestions!: QuestionBase<string>[];
  sections: any = [];
  addSectionSpots = [];
  additionalSections: any = [];
  themeKeys!: any[];
  selectedTheme: string = 'default';
  isThemeCustomized = false;
  themeColors: null;
  delay$: Observable<boolean> = of(false);
  screenSize: string = 'desktop';
  srcLink!: any;
  opened: boolean = false;
  saved: boolean = true;

  private length: { [key: string]: number } = {};
  private clonedQuestions: { [key: string]: QuestionBase<string>[][]; } = {};
  private themes!: any[];
  private page: any;

  constructor(
    private service: QuestionService,
    private renderer: Renderer2,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private cd: ChangeDetectorRef,
    private window: Window,
    private http: HttpClient,
  ) {}

  ngOnInit(): void {
    this.page = this.route.snapshot.paramMap.get('page');
    this.delay$ = of(true).pipe(delay(1000));
    this.srcLink = this.sanitizer.bypassSecurityTrustResourceUrl(
      `../../assets/${this.page}/index.html`
    );
    if ((<any>this.window).themes[this.page]) {
      this.themeKeys = Object.keys((<any>this.window).themes[this.page]);
      this.themes = (<any>this.window).themes[this.page];
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event: BeforeUnloadEvent) {
    event.preventDefault();
    return this.saved;
  }

  initialize(final: boolean = false): void {
    const frame = document.getElementById('frame') as HTMLIFrameElement;
    const cw = frame.contentWindow?.document;
    if (!cw) return;

    let tempSections = [];
    let sectionElements = cw.querySelectorAll('[data-editor-section]');
    for (let i = 0; i < sectionElements.length; i++) {
      tempSections.push(<HTMLElement>sectionElements[i]);
    }
    this.sections = tempSections;

    let sectionInsertSpots = cw.querySelectorAll('[data-editor-section-after]');
    tempSections = []
    for (let i = 0; i < sectionInsertSpots.length; i++) {
      tempSections.push(<HTMLElement>sectionInsertSpots[i]);
    }
    this.addSectionSpots = tempSections;

    const attributes = cw.querySelectorAll('[data-editor-field]');
    if (attributes) {
      this.questions = this.service.getQuestions(attributes);
      console.log({questions: this.questions})
      // this.cd.detectChanges();
    }

    let tempAdditionalSections = [];
    let additionalSectionsElements = cw.querySelectorAll('[data-editor-clonable]');
    for (let i = 0; i < additionalSectionsElements.length; i++) {
      let element = <HTMLElement>additionalSectionsElements[i];
      tempAdditionalSections.push(element.getAttribute('data-editor-clonable'));
    }
    this.additionalSections = tempAdditionalSections;
    console.log({tempAdditionalSections})

    this.renderer.listen('document', 'keyup', (evt) => {
      if(!evt.target.id) return;
      let element = cw?.querySelector(`[data-editor-field = ${evt.target.id}]`);
      let htmlElement = <HTMLElement>element;
      if (htmlElement && htmlElement.getAttribute('data-editor-type') !== 'link') {
        htmlElement.innerText = evt.target.value;
        this.saved = false;
      }
      if (htmlElement && htmlElement.getAttribute('data-editor-type') === 'link') {
        htmlElement.setAttribute('href', evt.target.value);
        this.saved = false;
      }
    });
    this.opened = true;
    this.cd.detectChanges();
    (<HTMLElement>frame).style.width = '100%';


    console.log('initialize called')
    let page = this.route.snapshot.paramMap.get('page');

    // const frame = document.getElementById('frame') as HTMLIFrameElement;
    // const cw = frame.contentWindow?.document;
    if (cw && !final && window.localStorage.getItem(`${page}-html`)) {
      console.log('loading saved called')
      let root = cw.querySelector(':root');
      const file = window.localStorage.getItem(`${page}-html`);//`<!DOCTYPE html>\n<html lang="en">\n${root.innerHTML}\n</html>`;
      // console.log(file);
      root.innerHTML = file;
      // this.loadDone.emit();
      this.initialize(true);
      // this.loadElements();

      return
    }
  }

  loadElements(){
    const page = this.route.snapshot.paramMap.get('page');
    if (!window.localStorage.getItem(page)) return;
    const savedData = JSON.parse(window.localStorage.getItem(page));
    const frame = document.getElementById('frame') as HTMLIFrameElement;
    const cw = frame.contentWindow?.document;
    if (!cw) return;
    cw?.querySelectorAll(`[data-editor-field]`).forEach(element => {
      let attribute = element.getAttribute(`data-editor-field`);
      if (savedData[`${attribute}`]) {
        if (element.getAttribute('data-editor-type') === 'file') {
          let src = new URL(savedData[`${attribute}`], document.baseURI).href;
          (<HTMLElement>element).setAttribute('src', src);
        } else if (element.getAttribute('data-editor-type') === 'link') {
          (<HTMLElement>element).setAttribute('href', savedData[`${attribute}`]);
        } else {
          (<HTMLElement>element).innerText = savedData[`${attribute}`];
        }
      }
      else {
        Object.keys(this.clonedQuestions).forEach((section) => {
          this.clonedQuestions[section].forEach((questions, i) => {
            questions.forEach((question, j) => {
              let element = cw?.querySelector(`[data-editor-field = ${question.key}]`);
              let htmlElement = <HTMLElement>element;
              if (htmlElement) {
                if (question.controlType === 'img'){
                  htmlElement.setAttribute('src', savedData.additionalSections[section][i][j]);
                } else if (question.controlType === 'link') {
                  htmlElement.setAttribute('href', savedData.additionalSections[section][i][j]);
                } else {
                  htmlElement.innerText = savedData.additionalSections[section][i][j];
                }
              }
            });
          });
        });
      }
    });
  }

  cloneSectionElements(section: string): void {
    const frame = document.getElementById('frame') as HTMLIFrameElement;
    const cw = frame.contentWindow?.document;
    console.log({section});
    
    let original = cw.querySelector(`[data-editor-clonable = ${section}]`);

    const last = original.querySelector(
      `:scope > [data-editor-isClonable-start = true]`
    );
    let lastChar = last.getAttribute('data-editor-field').slice(-1);
    let num: number;
    if (isNaN(parseInt(lastChar))) {
      num = 2;
    } else {
      num = parseInt(lastChar) + 1;
    }
    if (this.length[section]) {
      this.length[section] += 1;
    } else {
      this.length[section] = num;
    }
    let clone = original.cloneNode(true);
    this.incrementFieldCounters(<HTMLElement>clone, section);

    cw.querySelector(
      `[data-editor-clonable = ${section}]`
    ).parentNode.appendChild(clone);
    const addedAttributes = (<HTMLElement>clone).querySelectorAll(
      '[data-editor-field]'
    );
    this.addedQuestions = this.service.getQuestions(addedAttributes);
    if (this.clonedQuestions[`${section}`]?.length) {
      this.clonedQuestions[`${section}`].push(this.addedQuestions);
    } else {
      this.clonedQuestions[`${section}`] = [this.addedQuestions];
    }
    this.cd.detectChanges();
  }

  private incrementFieldCounters(clone: HTMLElement, section: string): void {
    let children = clone.children;
    for (let i = 0; i < children.length; i++) {
      if (children[i].getAttribute('data-editor-field')) {
        let field = children[i].getAttribute('data-editor-field');
        let label = children[i].getAttribute('data-editor-label');
        if (!isNaN(parseInt(field.slice(-1)))) {
          children[i].setAttribute(
            'data-editor-field',
            field.slice(0, -1) + `${this.length[section]}`
          );
        } else {
          children[i].setAttribute(
            'data-editor-field',
            field + `${this.length[section]}`
          );
        }
        if (!isNaN(parseInt(label.slice(-1)))) {
          children[i].setAttribute(
            'data-editor-label',
            label.slice(0, -1) + `${this.length[section]}`
          );
        } else {
          children[i].setAttribute(
            'data-editor-label',
            label + ` ${this.length[section]}`
          );
        }
      }
      if (children[i].children.length > 0) {
        this.incrementFieldCounters(<HTMLElement>children[i], section);
      }
    }
  }

  changeScreenSize(): void {
    let frame = document.querySelector('iframe');
    if (this.screenSize === 'desktop') {
      (<HTMLElement>frame).style.width = '100%';
    } else if (this.screenSize === 'tablet') {
      (<HTMLElement>frame).style.width = '768px';
    } else {
      (<HTMLElement>frame).style.width = '360px';
    }
  }

  changeTheme(event: any): void {
    const frame = document.getElementById('frame') as HTMLIFrameElement;
    const cw = frame.contentWindow?.document;
    if (cw) {
      let root = cw.querySelector(':root');
      if (root) {
        this.selectedTheme = event.value;
        let theme = this.themes[event.value];
        Object.keys(this.themes[event.value]).forEach((element) => {
          (<HTMLElement>root).style.setProperty(
            `--${element}`,
            theme[`${element}`]
          );
        });
        this.themeColors = theme;
      }
    }
  }

  changeImage(event: { key: string; url: string }): void {
    const frame = document.getElementById('frame') as HTMLIFrameElement;
    const cw = frame.contentWindow?.document;
    if (cw) {
      (<HTMLElement>(
        cw?.querySelector(`[data-editor-field = ${event.key}]`)
      )).setAttribute('src', event.url);
    }
  }

  scroll(id: { id: string }): void {
    this.srcLink = this.sanitizer.bypassSecurityTrustResourceUrl(
      `../../assets/${this.page}/index.html#${id}`
    );
  }

  removeSectionElements(event: { id: number; section: string }): void {
    const frame = document.getElementById('frame') as HTMLIFrameElement;
    const cw = frame.contentWindow?.document;
    let cloneable = cw.querySelectorAll(
      `[data-editor-clonable = ${event.section}]`
    );
    cloneable[event.id + 1].remove();
  }

  uploadHTML(orderId: number): void {
    const frame = document.getElementById('frame') as HTMLIFrameElement;
    const cw = frame.contentWindow?.document;
    if (cw) {
      let root = cw.querySelector(':root');
      const file = `<!DOCTYPE html>\n<html lang="en">\n${root.innerHTML}\n</html>`;
      // console.log(file);
      this.http.post(environment.baseUrl + '/page-content', {
        orderId,
        content: file,
        //page: 
      }).subscribe(() => console.log('uploaded code'))
    }
  }

  getColors(theme: string) {
    return this.themes[theme];
  }

  changeColors(theme: string, colors: string[]) {
    this.themes[theme] = Object.assign({}, colors);
    this.isThemeCustomized = true;
    this.changeTheme({value: theme});
  }
}
