import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CustomizeComponent } from './customize/customize.component';
import { HomeComponent } from './home/home.component';

const routes: Routes = [
  { path: 'edit/:page', pathMatch: 'full', component: CustomizeComponent },
  { path: '', pathMatch: 'full', component: HomeComponent },
  { path: '**', pathMatch: 'full', redirectTo: '' },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
