<div>
  <form *ngIf="form" (ngSubmit)="openFinalizeOrderDialog()" [formGroup]="form">
    <ng-container *ngFor="let section of sections; let i = index">

    
    <mat-accordion
      multi
      style="display: flex; justify-content: center"
    >
      <mat-expansion-panel
        (opened)="scrollToSection(i)"
        style="background-color: black; width: 95%; padding: 0"
        [expanded]="i === 0"
      >
        <mat-expansion-panel-header
          style="
            background-color: #27272b;
            height: 35px;
            margin-bottom: 5px;
            border-radius: 25px;
          "
        >
          <mat-panel-title>
            {{ section.getAttribute("data-editor-section") }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div *ngFor="let question of questions" class="form-row">
          <div
            *ngIf="
              question.section === section.getAttribute('data-editor-section')
            "
          >
            <app-question
              [question]="question"
              [form]="form"
              (uploadFinished)="fileUploaded($event)"
            ></app-question>
            <div
              *ngIf="
                question.clonableStart &&
                (!allCloneableQuestions[question.section + 'Section'] ||
                  !allCloneableQuestions[question.section + 'Section'].length)
              "
            >
              <button
                type="button"
                (click)="clone(question.section, 0)"
                mat-raised-button
                style="
                  border-radius: 50px;
                  background-color: lightgray;
                  color: #27272b;
                  margin-bottom: 1rem;
                "
              >
                ADD MORE
                <span class="material-icons"> add </span>
              </button>
            </div>
          </div>
        </div>
        <div *ngIf="additionalSectionsFormGroup && allCloneableQuestions">
          <div
            formGroupName="additionalSections"
            *ngFor="let item of additionalSectionsFormGroup.controls | keyvalue"
          >
            <ng-container
              [formArrayName]="item.key"
              *ngFor="
                let row of item.value.controls;
                let i = index;
                last as isLast
              "
            >
              <div
                *ngIf="
                  allCloneableQuestions[item.key][0].section ===
                  section.getAttribute('data-editor-section')
                "
                style="
                  margin: 1rem 0;
                  border: 2px solid #d3d3d3;
                  border-radius: 15px;
                  width: 290px;
                "
              >
                <div style="display: flex; justify-content: flex-end">
                  <button
                    type="button"
                    (click)="removeSectionControls(i, addedQuestions.length, item.key)"
                    mat-button
                    style="
                      background-color: lightgray;
                      color: #27272b;
                      border-radius: 10px;
                      margin: 16px 16px 16px 0;
                    "
                  >
                    REMOVE
                    <span class="material-icons"> clear </span>
                  </button>
                </div>
                <div
                  *ngFor="let question of row.controls; let j = index"
                  [formArrayName]="i"
                >
                  <div class="form-row">
                    <div
                      *ngIf="
                        allCloneableQuestions[item.key][
                          i * questionLength[item.key] + j
                        ].section ===
                        section.getAttribute('data-editor-section')
                      "
                    >
                      <div
                        [ngSwitch]="
                          allCloneableQuestions[item.key][
                            i * questionLength[item.key] + j
                          ].controlType
                        "
                      >
                        <mat-form-field
                          *ngSwitchCase="'textbox'"
                          appearance="outline"
                          style="width: 270px"
                        >
                          <mat-label>{{
                            allCloneableQuestions[item.key][
                              i * questionLength[item.key] + j
                            ].label
                          }}</mat-label>
                          <input
                            matInput
                            [id]="
                              allCloneableQuestions[item.key][
                                i * questionLength[item.key] + j
                              ].key
                            "
                            [defaultValue]="
                              allCloneableQuestions[item.key][
                                i * questionLength[item.key] + j
                              ].value
                            "
                            [formControlName]="j"
                            [required]="
                              allCloneableQuestions[item.key][
                                i * questionLength[item.key] + j
                              ].required
                            "
                          />
                          <mat-error *ngIf="!question.valid">
                            {{
                              allCloneableQuestions[item.key][
                                i * questionLength[item.key] + j
                              ].label
                            }}
                            is required
                          </mat-error>
                        </mat-form-field>

                        <mat-form-field
                          *ngSwitchCase="'link'"
                          appearance="outline"
                          style="width: 270px"
                        >
                          <mat-label>{{
                            allCloneableQuestions[item.key][
                              i * questionLength[item.key] + j
                            ].label
                          }}</mat-label>
                          <input
                            matInput
                            [id]="
                              allCloneableQuestions[item.key][
                                i * questionLength[item.key] + j
                              ].key
                            "
                            [defaultValue]="
                              allCloneableQuestions[item.key][
                                i * questionLength[item.key] + j
                              ].value
                            "
                            [formControlName]="j"
                            [required]="
                              allCloneableQuestions[item.key][
                                i * questionLength[item.key] + j
                              ].required
                            "
                          />
                          <mat-error *ngIf="!question.valid">
                            {{
                              allCloneableQuestions[item.key][
                                i * questionLength[item.key] + j
                              ].label
                            }}
                            is required
                          </mat-error>
                        </mat-form-field>

                        <mat-form-field
                          *ngSwitchCase="'textarea'"
                          appearance="outline"
                          style="width: 270px"
                        >
                          <mat-label>{{
                            allCloneableQuestions[item.key][
                              i * questionLength[item.key] + j
                            ].label
                          }}</mat-label>
                          <textarea
                            matInput
                            [id]="
                              allCloneableQuestions[item.key][
                                i * questionLength[item.key] + j
                              ].key
                            "
                            [defaultValue]="
                              allCloneableQuestions[item.key][
                                i * questionLength[item.key] + j
                              ].value
                            "
                            [rows]="6"
                            [formControlName]="j"
                            [required]="
                              allCloneableQuestions[item.key][
                                i * questionLength[item.key] + j
                              ].required
                            "
                          ></textarea>
                          <mat-error *ngIf="!question.valid">
                            {{
                              allCloneableQuestions[item.key][
                                i * questionLength[item.key] + j
                              ].label
                            }}
                            is required
                          </mat-error>
                        </mat-form-field>

                        <div *ngSwitchCase="'img'" class="input">
                          <label
                            style="
                              display: block;
                              text-align: left;
                              margin-bottom: 8px;
                              opacity: 0.7;
                            "
                          >
                            {{
                              allCloneableQuestions[item.key][
                                i * questionLength[item.key] + j
                              ].label
                            }}
                          </label>
                          <div style="display: flex; align-items: center">
                            <app-file-display
                              #display
                              [formControlName]="j"
                            ></app-file-display>
                            <app-file-upload
                              [key]="j"
                              [width]="'50px'"
                              [height]="'50px'"
                              [autoCleanUp]="true"
                              [autoUpload]="true"
                              (fileUploaded)="
                                onFileUploaded($event, item.key, i, j, display)
                              "
                            ></app-file-upload>
                          </div>

                          <div class="errorMessage" *ngIf="!question.valid">
                            {{
                              allCloneableQuestions[item.key][
                                i * questionLength[item.key] + j
                              ].label
                            }}
                            is required
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                *ngIf="
                  isLast &&
                  item.key ===
                    section.getAttribute('data-editor-section') + 'Section'
                "
              >
                <button
                  type="button"
                  (click)="clone(item.key, 1)"
                  mat-raised-button
                  style="
                    border-radius: 50px;
                    background-color: lightgray;
                    color: #27272b;
                  "
                >
                  ADD MORE
                  <span class="material-icons"> add </span>
                </button>
              </div>
            </ng-container>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>

      <button type="button" mat-stroked-button *ngIf="getAddSectionSpot(section)" 
        (click)="openSectionSelector(section, section['sectionAdded'])">
        {{section['sectionAdded'] ? 'Change Section' : 'Add Section'}}
      </button>
      <button type="button" mat-stroked-button *ngIf="getAddSectionSpot(section) && section['sectionAdded']" (click)="removeSection(section)">
        Remove Section
      </button>
    </ng-container>
    <div class="form-row">
      <button
        type="submit"
        [disabled]="!form.valid"
        mat-raised-button
        style="border-radius: 50px; background-color: #1e8de1"
        class="button"
      >
        PUBLISH
      </button>
      <button
        type="button"
        (click)="save()"
        [disabled]="saved"
        mat-raised-button
        style="border-radius: 50px; background-color: #1e8de1"
        class="button"
      >
        SAVE
      </button>
    </div>
  </form>
</div>
