import {
  HttpClient,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AsyncValidator,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';
import { catchError, forkJoin, map, Observable, of } from 'rxjs';

@Injectable()
export class DomainValidator implements AsyncValidator {
  constructor(private http: HttpClient) {}
  validate(
    control: AbstractControl
  ): Promise<ValidationErrors> | Observable<ValidationErrors> {
    let verisignCheck$!: Observable<boolean>;
    let cloudflareCheck$!: Observable<boolean>;
    let verisignNetCheck$!: Observable<boolean>;
    let gandiNetCheck$!: Observable<boolean>;
    let verisignOrgCheck$!: Observable<boolean>;
    let networkSolutionsOrgCheck$!: Observable<boolean>;
    let checks: [Observable<boolean | null>, Observable<boolean | null>];
    const domainName = control.value.split('.');
    let domainEnd: string = domainName[domainName.length - 1];
    if (domainEnd === 'com') {
      verisignCheck$ = this.http
        .get<HttpResponse<any>>(
          `https://rdap.verisign.com/com/v1/domain/${control.value}`,
          { observe: 'response' }
        )
        .pipe(
          map((res) => {
            if (res.status === 200) {
              return false;
            } else {
              return null;
            }
          }),
          catchError((error: HttpErrorResponse) => {
            if (error.status === 404) {
              return of(true);
            }
            return of(null);
          })
        );

      cloudflareCheck$ = this.http
        .get<HttpResponse<any>>(
          `https://rdap.cloudflare.com/rdap/v1/domain/${control.value}`,
          { observe: 'response' }
        )
        .pipe(
          map((res) => {
            if (res.status === 200) {
              return false;
            } else {
              return null;
            }
          }),
          catchError((error: HttpErrorResponse) => {
            if (error.status === 404) {
              return of(true);
            }
            return of(null);
          })
        );
      checks = [verisignCheck$, cloudflareCheck$];
    } else if (domainEnd === 'net') {
      verisignNetCheck$ = this.http
        .get<HttpResponse<any>>(
          `https://rdap.verisign.com/net/v1/domain/${control.value}`,
          { observe: 'response' }
        )
        .pipe(
          map((res) => {
            if (res.status === 200) {
              return false;
            } else {
              return null;
            }
          }),
          catchError((error: HttpErrorResponse) => {
            if (error.status === 404) {
              return of(true);
            }
            return of(null);
          })
        );

      gandiNetCheck$ = this.http
        .get<HttpResponse<any>>(
          `https://rdap.gandi.net/domain/${control.value}`,
          { observe: 'response' }
        )
        .pipe(
          map((res) => {
            if (res.status === 200) {
              return false;
            } else {
              return null;
            }
          }),
          catchError((error: HttpErrorResponse) => {
            if (error.status === 404) {
              return of(true);
            }
            return of(null);
          })
        );
      checks = [verisignNetCheck$, gandiNetCheck$];
    } else if (domainEnd === 'org') {
      verisignOrgCheck$ = this.http
        .get<HttpResponse<any>>(
          `https://rdap.publicinterestregistry.net/rdap/org/domain/${control.value}`,
          { observe: 'response' }
        )
        .pipe(
          map((res) => {
            if (res.status === 200) {
              return false;
            } else {
              return null;
            }
          }),
          catchError((error: HttpErrorResponse) => {
            if (error.status === 404) {
              return of(true);
            }
            return of(null);
          })
        );
      networkSolutionsOrgCheck$ = this.http
        .get<HttpResponse<any>>(
          `https://rdap.networksolutions.com/rdap/domain/lol.org/${control.value}`,
          { observe: 'response' }
        )
        .pipe(
          map((res) => {
            if (res.status === 200) {
              return false;
            } else {
              return null;
            }
          }),
          catchError((error: HttpErrorResponse) => {
            if (error.status === 404) {
              return of(true);
            }
            return of(null);
          })
        );

      checks = [verisignOrgCheck$, networkSolutionsOrgCheck$];
    }
    // https://rdap.verisign.com/com/v1/domain/ferido07.com
    // https://rdap.cloudflare.com/rdap/v1/domain/ACROSSEXPRESS.COM

    if (!control.value || !checks?.length) {
      return of(null);
    }
    return forkJoin({ one: checks[0], two: checks[1] }).pipe(
      map(({ one, two }): null | ValidationErrors => {
        let verisignCheck = one;
        let cloudflareCheck = two;
        if (verisignCheck === false || cloudflareCheck === false) {
          return {
            domain: { invalid: true, message: 'Domain is already taken' },
          };
        } else if (verisignCheck === true) {
          return null;
        } else if (verisignCheck === null && cloudflareCheck === true) {
          return null;
        } else if (verisignCheck === null && cloudflareCheck === null) {
          return {
            domain: {
              invalid: false,
              message: 'Can not determine domain availability',
            },
          };
        } else {
          return { domain: { invalid: true } };
        }
      })
    );
  }
}
