import { HttpClient } from '@angular/common/http';
import { Component, Input, Inject, Output, EventEmitter, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormArray,
  Validators,
} from '@angular/forms';

import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { FileDisplayComponent } from '../file-display/file-display.component';
import { SectionSelectorComponent } from '../section-selector/section-selector.component';

import { QuestionBase } from '../shared/question-base';
import { QuestionControlService } from '../shared/question-control.service';
import { DomainValidator } from './domain-validator';
export interface DialogData {
  fullName: string;
  phone: string;
  email: string;
  sellerId: string;
  domain: string;
  title: string;
  siteName: string;
  icon: any;
  logo: any;
  note: string;
  links: string;
}
@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.css'],
  providers: [QuestionControlService],
})
export class DynamicFormComponent implements OnInit {

  @Input() sections: any = [];
  @Input() addSectionSpots = [];
  @Input() theme: string = '';
  @Input() isThemeCustomized: boolean;
  @Input() themeColors = null;
  @Input() saved: boolean;
  allCloneableQuestions: { [key: string]: QuestionBase<string>[]; } = {};
  questionLength: { [key: string]: number; } = {};
  form!: FormGroup;

  private _questions: QuestionBase<string>[] | null = [];
  private _addedQuestions: QuestionBase<string>[] | null = [];
  private _additionalSections: string[];
  private siteInfo!: DialogData;

  @Output() uploadFinished: EventEmitter<{ key: string; url: string }> = new EventEmitter();
  @Output() tabOpened: EventEmitter<{ id: string }> = new EventEmitter();
  @Output() sectionRemoved: EventEmitter<{ id: number; section: string }> = new EventEmitter();
  @Output() sectionAdded: EventEmitter<string> = new EventEmitter();
  @Output() saveDone: EventEmitter<boolean> = new EventEmitter();
  @Output() loadDone: EventEmitter<boolean> = new EventEmitter();
  @Output() formSubmitted: EventEmitter<number> = new EventEmitter();
  @Output() newSectionAdded: EventEmitter<void> = new EventEmitter();
  @Output() newSectionRemoved: EventEmitter<void> = new EventEmitter();

  constructor(
    private qcs: QuestionControlService,
    public dialog: MatDialog,
    private http: HttpClient,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
      this.loadSaved();
  }

  get questions(): QuestionBase<string>[] | null {
    return this._questions;
  }

  @Input()
  set questions(value: QuestionBase<string>[] | null) {
    if (!(value && value.length > 0)) return;
    this._questions = value;
    this.form = this.qcs.toFormGroup(this.questions as QuestionBase<string>[]);
    this.form.addControl('additionalSections', this.fb.group({}));
  }

  get addedQuestions(): QuestionBase<string>[] | null {
    return this._addedQuestions;
  }

  @Input()
  set addedQuestions(value: QuestionBase<string>[] | null) {
    if (!(value && value.length > 0)) return;
    this._addedQuestions = value;
    let formControls = this.qcs.toFormControls(
      this.addedQuestions as QuestionBase<string>[]
    );
    let section = this.addedQuestions[0].section;
    this.addSectionControls(formControls, `${section}Section`);

    let temp = this.allCloneableQuestions;
    if (temp[`${section}Section`]?.length) {
      temp[`${section}Section`].push(...this.addedQuestions);
      this.allCloneableQuestions = temp;
      return;
    }
    temp[`${section}Section`] = [...this.addedQuestions];
    this.questionLength[`${section}Section`] = this.addedQuestions.length;
    this.allCloneableQuestions = temp;
  }

  @Input()
  set additionalSections(value: string[]) {
    if (!(value && value.length > 0)) return;
    this._additionalSections = value;
    this._additionalSections.forEach((section) => {
      (<FormGroup>this.form.get('additionalSections')).addControl(
        section,
        this.fb.array([])
      );
    });
    this.loadSaved();
  }

  // Not used so far
  get additionalSections(): string[] {
    return this._additionalSections;
  }

  get additionalSectionsFormGroup() {
    return this.form.get('additionalSections') as FormGroup;
  }

  private addSectionControls(controls: FormControl[], section: string) {
    let formArray = new FormArray([]);
    controls.forEach((control) => {
      formArray.push(control);
    });
    (<FormArray>this.form.get(`additionalSections.${section}`)).push(formArray);
  }

  removeSectionControls(start: number, end: number, section: string): void {
    let formArray = <FormArray>this.form.get(`additionalSections.${section}`);
    formArray.controls.splice(start, 1);
    this.allCloneableQuestions[section].splice(start * end, end);
    this.sectionRemoved.emit({ id: start, section });
    this.saved = false;
    this.saveDone.emit(false);
  }

  clone(section: string, num: number): void {
    if (num === 0) {
      this.sectionAdded.emit(`${section}Section`);
      this.saved = false;
      this.saveDone.emit(false);
      return;
    }
    this.sectionAdded.emit(section);
    this.saved = false;
    this.saveDone.emit(false);
  }

  save(): void {
    let page = this.route.snapshot.paramMap.get('page');
    window.localStorage.setItem(page, JSON.stringify(this.form.getRawValue()));
    this.saved = true;
    this.saveDone.emit(true);

    const frame = document.getElementById('frame') as HTMLIFrameElement;
    const cw = frame.contentWindow?.document;
    if (cw) {
      let root = cw.querySelector(':root');
      const file = `<!DOCTYPE html>\n<html lang="en">\n${root.innerHTML}\n</html>`;
      // console.log(file);
      window.localStorage.setItem(`${page}-html`, file);
     
    }
  }

  loadSaved(): void {
    // console.log('load called')
    let page = this.route.snapshot.paramMap.get('page');

    // const frame = document.getElementById('frame') as HTMLIFrameElement;
    // const cw = frame.contentWindow?.document;
    // if (cw) {
    //   let root = cw.querySelector(':root');
    //   const file = window.localStorage.getItem(`${page}-html`);//`<!DOCTYPE html>\n<html lang="en">\n${root.innerHTML}\n</html>`;
    //   // console.log(file);
    //   root.innerHTML = file;
    //   this.loadDone.emit();
    //   return
    // }
    return;

    if (!window.localStorage.getItem(page)) return;
    let savedData = JSON.parse(window.localStorage.getItem(page));
    let keys = Object.keys(savedData.additionalSections);
    for (let i = 0; i < keys.length; i++ ){
      let arr = savedData.additionalSections[keys[i]];
      for (let j = 0; j < arr.length; j++){
        this.clone(keys[i], 1);
      }
    }
    for (const field in savedData) {
      if (field !== 'additionalSections') {
        this.form.get(`${field}`).setValue(savedData[`${field}`]);
      } else {
        for (const section in savedData.additionalSections) {
          let temp = ((<FormArray>this.form.get(`additionalSections.${section}`)).controls);
          temp.forEach((set, i)=>{
            (<FormArray>set).controls.forEach((field, j) => {
              field.setValue(savedData.additionalSections[section][i][j]);
            })
          })
        }
      }
    }
    this.loadDone.emit();
  }

  fileUploaded(event: { key: any; url: any }): void {
    this.form.get(event.key).setValue(event.url);
    this.uploadFinished.emit(event);
    this.saved = false;
    this.saveDone.emit(false);
  }

  onFileUploaded(
    fileUploadEvent: { url: string; index: number },
    section: string,
    index: number,
    secondIndex: number,
    display: FileDisplayComponent
  ): void {
    let formArray = (<FormArray>this.form.get(`additionalSections.${section}`))
      .controls[index];
    (<FormArray>formArray).controls[secondIndex].setValue(fileUploadEvent.url);

    display.urls = new Set<string>([fileUploadEvent.url]);
    this.uploadFinished.emit({
      key: this.allCloneableQuestions[section][
        index * this.questionLength[section] + secondIndex
      ].key,
      url: fileUploadEvent.url,
    });
    this.saved = false;
    this.saveDone.emit(false);
  }

  getAddSectionSpot(section: HTMLElement) {
    // console.log({addSectionSpots: this.addSectionSpots});
     
    const insertionSpot: HTMLElement = this.addSectionSpots.find((sp: HTMLElement) => sp.getAttribute('data-editor-section-after') === section.getAttribute('data-editor-section'));
    if (insertionSpot?.innerHTML) {
      section['sectionAdded'] = true;
    }
    return  insertionSpot ;
  }

  openSectionSelector(section: HTMLElement, change: boolean = false): void {
    // console.log({section});
    const s = section.getAttribute('data-editor-section');
    const insertInto = section.parentElement.parentElement.querySelector(`[data-editor-section-after='${s}']`);
    console.log({s,insertInto, section})
    
    const dialogRef = this.dialog.open(SectionSelectorComponent);
    dialogRef.afterClosed().subscribe(sectionToBeAdded => {
      if(!sectionToBeAdded){
        return;
      }
      // console.log({section: sectionToBeAdded});
      
      this.http.get(sectionToBeAdded.url, { responseType: 'text' }).subscribe(result => {
        // console.log({result});
        
        let div = section.ownerDocument.createElement('div');
        div.innerHTML = result;
        let newElementSectionName = div.querySelector('[data-editor-section]').getAttribute('data-editor-section');
        const elementAlreadyInDOM = section.ownerDocument.querySelector(`[data-editor-section='${newElementSectionName}']`);
        if (elementAlreadyInDOM) {
          this._snackBar.open('Cannot add a section which is already added', 'Close', {duration: 5000})
          return;
        }

        section['sectionAdded'] = true;

        if (!change) {
          insertInto.innerHTML = result;
          // add another section insertion spot
          const newlyInsertedSection = insertInto.querySelector('[data-editor-section]');
          let newlyInsertedSectionName = newlyInsertedSection.getAttribute('data-editor-section');
          
          // const allSectionsWithSimilarName =  insertInto.parentElement.querySelectorAll(`[data-editor-section^="${newlyInsertedSectionName}]"`);
          // if (allSectionsWithSimilarName.length) {
          //   newlyInsertedSectionName = newlyInsertedSectionName + '-' + allSectionsWithSimilarName.length;
          //   newlyInsertedSection.setAttribute('data-editor-section', newlyInsertedSectionName);
          // }
      

          const additionalInsertionSpot = document.createElement('div');
          additionalInsertionSpot.setAttribute('data-editor-section-after', newlyInsertedSectionName)
          insertInto.parentNode.insertBefore(additionalInsertionSpot, insertInto.nextSibling)
          // insertInto.parentElement.appendChild(additionalInsertionSpot);
        } else {
          const oldSectionName = insertInto.querySelector('[data-editor-section]').getAttribute('data-editor-section');
          const linkedInsertionSpot = insertInto.parentElement.parentElement.querySelector(`[data-editor-section-after=${oldSectionName}]`);
          
          insertInto.innerHTML = result;
          const newSectionName = insertInto.querySelector('[data-editor-section]').getAttribute('data-editor-section');
          linkedInsertionSpot.setAttribute('data-editor-section-after', newSectionName);
        }


        //
        this.saved = false;
        this.newSectionAdded.emit();
      })
    });
    
  }

  removeSection(section: HTMLElement): void {
    const s = section.getAttribute('data-editor-section');
    const insertInto = section.parentElement.parentElement.querySelector(`[data-editor-section-after='${s}']`);
    const x = insertInto.querySelector('[data-editor-section]');
    const insertionSpotName = x.getAttribute('data-editor-section');
    const elementToBeRemoved = section.parentElement.parentElement.querySelector(`[data-editor-section-after=${insertionSpotName}]`);
    if (elementToBeRemoved.innerHTML === '') {
      elementToBeRemoved.remove();
      insertInto.innerHTML = '';
    } else {
      let newAfter = insertInto.getAttribute('data-editor-section-after');
      elementToBeRemoved.setAttribute('data-editor-section-after', newAfter);
      insertInto.remove();
    }
    
    section['sectionAdded'] = false;
    this.saved = false;
    this.newSectionRemoved.emit();
  }

  scrollToSection(i: number): void {
    this.tabOpened.emit(this.sections[i].id);
  }

  openFinalizeOrderDialog(): void {
    // console.log(this.form.controls)

    const dialogRef = this.dialog.open(DialogComponent, {
      width: '720px',
      data: {
        formData: this.form.getRawValue(),
        theme: this.theme,
        siteInfo: this.siteInfo,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.siteInfo = result;
      if (this.siteInfo) {
        // console.log({
        //   SI: this.siteInfo,
        //   FD: this.form.getRawValue(),
        //   th: this.theme,
        //   template: this.route.snapshot.paramMap.get('page'),
        // });

        // form submission
        this.http
          .post<{id: number}>(environment.baseUrl + '/', {
            siteName: this.siteInfo.siteName,
            faviconUrl: this.siteInfo.icon,
            domainName: this.siteInfo.domain,
            template: this.route.snapshot.paramMap.get('page'),
            theme: this.theme + (this.isThemeCustomized? `-customized ${JSON.stringify(this.themeColors)}` : ''),
            templateData: this.form.getRawValue(),
            title: this.siteInfo.title,

            name: this.siteInfo.fullName,
            email: this.siteInfo.email,
            phoneNumber: this.siteInfo.phone,

            sellerId: this.siteInfo.sellerId || null,
            note: this.siteInfo.note,
            links: this.siteInfo.links,
          })
          .subscribe((order) => {
            // console.log('success');
            this.formSubmitted.emit(order.id);
          });
        this._snackBar.open(
          'Your order has been received, We will contact you right away.',
          'Ok'
        );

 

      }
    });
  }
}

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  providers: [DomainValidator],
})
export class DialogComponent {
  phone =
    /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
  validEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  valid = new RegExp('^((?!-)[A-Za-z0-9-]{1,63}(?<!-)\\.)[A-Za-z]{2,6}');
  form: FormGroup = new FormGroup({
    fullName: new FormControl(''),
    phone: new FormControl('', [Validators.pattern(this.phone)]),
    email: new FormControl('', [Validators.pattern(this.validEmail)]),
    sellerId: new FormControl(''),
    domain: new FormControl(
      '',
      [Validators.pattern(this.valid)],
      [this.domainValidator.validate.bind(this.domainValidator)]
    ),
    siteName: new FormControl(),
    icon: new FormControl(),
    note: new FormControl(),
    links: new FormControl(),
    agree: new FormControl(false),
  });
  isSellerIdHidden = false;

  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private domainValidator: DomainValidator,
    private route: ActivatedRoute
  ) {
    if (this.route.snapshot.queryParamMap.get('sellerId')) {
      this.form.get('sellerId').setValue(this.route.snapshot.queryParamMap.get('sellerId'));
      this.isSellerIdHidden = true;
    }
  }

  getDomainErrorMessage() {
    if (this.form.get('domain').hasError('required')) {
      return 'Domain name is required';
    }
    if (
      this.form.get('domain').invalid &&
      this.form.get('domain').errors['domain'] &&
      this.form.get('domain').errors['domain']['message']
    ) {
      return this.form.get('domain').errors['domain']['message'];
    } else {
      return 'Invalid domain name';
    }
  }

  getEmailErrorMessage() {
    if (this.form.get('email').hasError('required')) {
      return '';
    }
    return this.form.get('email').invalid ? 'Invalid email' : '';
  }

  getPhoneErrorMessage() {
    if (this.form.get('phone').hasError('required')) {
      return '';
    }
    return this.form.get('phone').invalid ? 'Invalid phone number' : '';
  }

  onFileUploaded(
    fileUploadEvent: { url: string; index: number },
    display: FileDisplayComponent
  ): void {
    this.form.get('icon').setValue(fileUploadEvent.url);
    display.urls = new Set<string>([fileUploadEvent.url]);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {}
}
