<div>
    <h1>Sections</h1>

    <div style="display: flex;">
        <div style="width: 150px;">
            <h2 *ngFor="let sectionGroup of sections | keyvalue"
                (click)="selectedSection = sectionGroup.key">
                <span [style.border-bottom]="sectionGroup.key === selectedSection ? '2px solid white' : ''">
                    {{ sectionGroup.key | titlecase }}
                </span>  
            </h2>       
        </div>
        <div style="display: flex; flex-wrap: wrap; overflow-y: scroll; height:400px; width: 1004px; padding-inline: 20px;">
            <ng-container *ngFor="let sectionGroup of sections | keyvalue">
                <div *ngFor="let section of sectionGroup.value" 
                    style="margin: 10px;"
                    [style.display]="selectedSection === sectionGroup.key ? 'block':'none'" 
                    (click)="selectSection(section)">
                    <img alt="sectionName" [src]="section.thumbnail" [attr.alt]="sectionGroup.key" width="480px" />
                </div>
            </ng-container>
        </div>
    </div>

</div>