import { Component, Inject, Input, OnInit, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { merge, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  IFileUploadService,
  UploadProgress,
} from './service/file-upload.service.interface';
import { FILE_UPLOAD_SERVICE } from './service/file-upload.service.provider';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css'],
})
export class FileUploadComponent implements OnInit {
  @Input() width = '50px';
  @Input() height = '50px';
  @Input() autoUpload = true;
  @Input() autoCleanUp = true;
  @Input() key = '';
  // @Input() formControlName: string;

  uploading = false;
  uploadCompletedCount = 0;

  filesToUpload!: File[];
  uploadProgresses: UploadProgress[] = [];
  fileUrls: string[] = [];

  // @Output() filesSelected: EventEmitter<File[]> = new EventEmitter();
  @Output() uploadStarted: EventEmitter<File[]> = new EventEmitter();
  @Output() uploadProgress: EventEmitter<{
    index: number;
    progress: UploadProgress;
  }> = new EventEmitter();
  @Output() fileUploaded: EventEmitter<{ index: number; url: string }> =
    new EventEmitter();
  @Output() uploadFinished: EventEmitter<string[]> = new EventEmitter();

  constructor(
    @Inject(FILE_UPLOAD_SERVICE) private fileUploadService: IFileUploadService
  ) {}

  ngOnInit(): void {}

  selectFiles(files: FileList): void {
    this.filesToUpload = [];
    this.uploadProgresses = [];
    for (let i = 0; i < files.length; i++) {
      this.filesToUpload.push(files.item(i) as File);
    }
    // this.filesSelected.emit(this.filesToUpload);

    if (this.autoUpload) {
      this.startUpload();
    }
  }

  startUpload(): void {
    if (this.filesToUpload.length <= 0) {
      return;
    }

    const progressMap = this.getUploads().map((progress, index) => {
      return progress.pipe(
        map((p) => ({
          index,
          p,
        }))
      );
    });
    merge(...progressMap).subscribe((progress) => {
      this.uploadProgress.emit({ index: progress.index, progress: progress.p });
    });
    this.uploading = true;
    this.uploadStarted.emit(this.filesToUpload);
  }

  // clearFileUpload(index: number): void {
  //   this.uploadProgresses[index] = undefined;
  //   this.filesToUpload[index] = undefined;
  // }

  clearUploadProgress(url: string): void {
    const urlIndex = this.fileUrls.findIndex((u) => u === url);
    this.uploadProgresses[urlIndex] = undefined;
    this.filesToUpload[urlIndex] = undefined;
  }

  clearAllUpload(): void {
    if (this.uploadCompletedCount === this.filesToUpload.length) {
      this.cleanUp();
    }
  }

  private getUploads(): Observable<UploadProgress>[] {
    const uploadProgresses$ = this.filesToUpload.map(
      (file, index) =>
        this.fileUploadService.upload(file).pipe(
          map((progress) => {
            this.uploadProgresses[index] = progress;

            if (progress.state === 'DONE') {
              this.uploadCompletedCount++;
              this.fileUrls[index] = progress.url;
              this.fileUploaded.emit({
                index,
                url: progress.url,
              });
            }
            if (this.uploadCompletedCount === this.filesToUpload.length) {
              this.uploadFinished.emit(this.fileUrls);
              if (this.autoCleanUp) {
                this.cleanUp();
              }
            }
            return progress;
          })
        ) // .pipe(catchError(err => of(err)))
    );
    return uploadProgresses$;
  }

  private setUp(): void {}

  private cleanUp(): void {
    this.uploading = false;
    this.filesToUpload = [];
    this.uploadProgresses = [];
    this.uploadCompletedCount = 0;
    this.fileUrls = [];
  }
}
