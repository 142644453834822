<div style="display: flex; flex-wrap: wrap">
  <div style="display: flex; flex-wrap: wrap">
    <ng-container *ngFor="let progress of uploadProgresses; let i = index">
      <div
        style="padding: 5px 5px"
        *ngIf="progress"
        [style.width]="width"
        [style.height]="height"
        [matTooltip]="filesToUpload[i]?.name"
      >
        <mat-spinner
          [mode]="
            progress.state === 'PENDING' ? 'indeterminate' : 'determinate'
          "
          [value]="progress.progress"
          [diameter]="+height.split('px')[0] - 10"
        >
        </mat-spinner>
      </div>
    </ng-container>
  </div>

  <div>
    <label
      class="mat-raised-button"
      [class.disabled]="uploading"
    >
      CHANGE
      <!-- <mat-hint>1x1 is Recomended</mat-hint> -->
      <input
        type="file"
        accept="image/*"
        style="display: none"
        (change)="selectFiles($event.target.files)"
        multiple
        [disabled]="uploading"
      />
      <!-- <mat-icon>upload</mat-icon> -->
      <!-- <mat-form-field appearance="fill">
      <ngx-mat-file-input
        [formControl]=""
        onselect="selectFiles($event.target.files)"
        accept="image/*"
        [multiple]="false"
        [disabled]="uploading"
      >
      </ngx-mat-file-input> -->
      <!-- </mat-form-field> -->
    </label>
  </div>
</div>
