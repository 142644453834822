<div [formGroup]="form">
  <div [ngSwitch]="question.controlType">
    <mat-form-field
      *ngSwitchCase="'textbox'"
      appearance="outline"
      style="width: 270px"
    >
      <mat-label>{{ question.label }}</mat-label>
      <!--suppress CheckEmptyScriptTag -->
      <input
        matInput
        [id]="question.key"
        [defaultValue]="question.value"
        [formControlName]="question.key"
        [required]="question.required"
      />
      <mat-error *ngIf="!isValid">{{ question.label }} is required</mat-error>
    </mat-form-field>

    <mat-form-field
      *ngSwitchCase="'link'"
      appearance="outline"
      style="width: 270px"
    >
      <mat-label>{{ question.label }}</mat-label>
      <!--suppress CheckEmptyScriptTag -->
      <input
        matInput
        [id]="question.key"
        [defaultValue]="question.value"
        [formControlName]="question.key"
        [required]="question.required"
      />
      <mat-error *ngIf="!isValid">{{ question.label }} is required</mat-error>
    </mat-form-field>

    <mat-form-field
      *ngSwitchCase="'textarea'"
      appearance="outline"
      style="width: 270px"
    >
      <mat-label>{{ question.label }}</mat-label>
      <textarea
        matInput
        [id]="question.key"
        [defaultValue]="question.value"
        [rows]="6"
        [formControlName]="question.key"
        [required]="question.required"
      ></textarea>
      <mat-error *ngIf="!isValid">{{ question.label }} is required</mat-error>
    </mat-form-field>

    <div *ngSwitchCase="'img'" class="input" style="margin: 1rem">
      <label style="display: block; text-align: left; margin-bottom: 8px; opacity: 0.7;">
        {{ question.label }}
      </label>
      <div style="display: flex; align-items: center;">
        <app-file-display
          [urls]="urls"
          [formControlName]="question.key"
        ></app-file-display>
        <app-file-upload
          [key]="question.key"
          [width]="'50px'"
          [height]="'50px'"
          [autoCleanUp]="true"
          [autoUpload]="true"
          (fileUploaded)="onFileUploaded($event)"
        >
        </app-file-upload>
      </div>

      <div class="errorMessage" *ngIf="!isValid">
        {{ question.label }} is required
      </div>
    </div>
  </div>
</div>
