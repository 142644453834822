<div style="text-align: center; background-color: black">
  <h1 mat-dialog-title>Finalize Order</h1>
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div mat-dialog-content>
      <p style="text-align: start; padding-left: 10px; font-weight: 400">
        Personal Info
      </p>
      <mat-form-field appearance="outline" style="margin: 0.5rem">
        <mat-label>Full Name</mat-label>
        <input matInput formControlName="fullName" required />
      </mat-form-field>
      <mat-form-field appearance="outline" style="margin: 0.5rem">
        <mat-label>Phone Number</mat-label>
        <input matInput formControlName="phone" required />
        <mat-error *ngIf="form.get('phone').invalid">{{
          getPhoneErrorMessage()
        }}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" style="margin: 0.5rem">
        <mat-label>Email</mat-label>
        <input matInput formControlName="email" required />
        <mat-error *ngIf="form.get('email').invalid">{{
          getEmailErrorMessage()
        }}</mat-error>
      </mat-form-field>
      <p style="text-align: start; padding-left: 10px; font-weight: 400">
        Site Info
      </p>
      <p style="text-align: start; opacity: 0.8; padding-left: 10px">Pricing</p>
      <p style="text-align: start; padding-left: 10px">
        The packages include your self-designed Website, Domain name, Hosting
        and Search Engine Optimization
      </p>
      <div
        style="display: flex; justify-content: space-around; text-align: center"
      >
        <div style="padding: 1rem; margin: 1rem; border: 1px solid white">
          <span style="font-weight: bold; font-size: large">1,999</span>
          birr/year<br />
          <span style="color: #1e8de1; font-size: large">.com</span>
        </div>
        <div style="padding: 1rem; margin: 1rem; border: 1px solid white">
          <span style="font-weight: bold; font-size: large">2,399</span>
          birr/year<br />
          <span style="color: #1e8de1; font-size: large">.net</span>
        </div>
        <div style="padding: 1rem; margin: 1rem; border: 1px solid white">
          <span style="font-weight: bold; font-size: large">2,899</span>
          birr/year<br />
          <span style="color: #1e8de1; font-size: large">.org</span>
        </div>
      </div>
      <p style="text-align: center; opacity: 0.7; font-weight: 300">
        you can pay via mobile banking, bank deposit or in person
      </p>
      <p style="text-align: center; opacity: 0.7; font-weight: 400">
        * if you'd like to buy a different top-level domain name<br />
        or have any question at all call us on
        <span style="color: #1e8de1"> +251 98335 4391 </span>
      </p>
      <mat-form-field appearance="outline" style="margin: 0.5rem">
        <mat-label>Domain Name</mat-label>
        <input matInput formControlName="domain" required />
        <mat-error *ngIf="form.get('domain').invalid">{{
          getDomainErrorMessage()
        }}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" style="margin: 0.5rem">
        <mat-label>Site Name</mat-label>
        <input matInput formControlName="siteName" required />
      </mat-form-field>
      <div
        style="
          width: 201px;
          display: inline-flex;
          align-items: flex-start;
          margin: 0.5rem;
          justify-content: center;
        "
      >
        <div
          style="
            display: flex;
            align-items: center;
            align-content: center;
            margin: 0.5rem;
          "
        >
          <p style="margin: 0 0.5rem">Logo:</p>
          <app-file-display #display></app-file-display>
          <app-file-upload
            [width]="'50px'"
            [height]="'50px'"
            [autoCleanUp]="true"
            [autoUpload]="true"
            (fileUploaded)="onFileUploaded($event, display)"
            #upload
          ></app-file-upload>
        </div>
      </div>
      <p style="text-align: start; padding-left: 10px; font-weight: 400">
        Other
      </p>
      <div style="text-align: start" [style.display]="isSellerIdHidden ? 'none' : 'block'">
        <mat-form-field appearance="outline" style="margin: 0.5rem 1.1rem">
          <mat-label>Seller Id(optional)</mat-label>
          <input matInput formControlName="sellerId" />
        </mat-form-field>
      </div>
      <div style="text-align: start">
        <mat-form-field
          appearance="outline"
          style="margin: 0.5rem 1.1rem; width: 638px"
        >
          <mat-label>Note</mat-label>
          <textarea matInput formControlName="note"></textarea>
        </mat-form-field>
      </div>
      <div style="text-align: start">
        <mat-form-field
          appearance="outline"
          style="margin: 0.5rem 1.1rem; width: 638px"
        >
          <mat-label>Links</mat-label>
          <textarea
            matInput
            formControlName="links"
            placeholder="https://facebook.com/,  https://linkedin.com/"
          ></textarea>
        </mat-form-field>
      </div>
    </div>

    <section class="example-section">
      <mat-checkbox formControlName="agree" color="primary">
        I accept the
        <a
          href="http://box.et/terms.html"
          target="_blank"
          style="color: #1e8de1"
          >terms and conditions</a
        >.
      </mat-checkbox>
    </section>
    <div mat-dialog-actions style="display: flex; justify-content: center">
      <button mat-button (click)="onNoClick()">Back</button>
      <button
        mat-button
        type="submit"
        [mat-dialog-close]="form.getRawValue()"
        [disabled]="!form.valid || !form.get('agree').value"
      >
        Submit Order
      </button>
    </div>
  </form>
</div>
