import {
  HttpClient,
  HttpErrorResponse,
  HttpEventType,
  HttpResponse,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { merge, Observable, of, throwError } from 'rxjs';
import { map, catchError, filter } from 'rxjs/operators';
import {
  IFileUploadService,
  UploadProgress,
} from './file-upload.service.interface';
import { BASE_URL } from './file-upload.service.provider';

@Injectable({
  providedIn: 'root',
})
export class FileUploadService implements IFileUploadService {
  // baseUrl = ''
  constructor(
    private http: HttpClient,
    @Inject(BASE_URL) private baseUrl: string
  ) {
    // this.baseUrl
  }

  upload(fileToUpload: File): Observable<UploadProgress> {
    const url = `${this.baseUrl}/upload`;
    const formData: FormData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);

    const initialProgress: UploadProgress = {
      progress: 0,
      state: 'PENDING',
      url: undefined,
    };

    const makeRequest = this.http
      .post(url, formData, { reportProgress: true, observe: 'events' })
      .pipe(
        filter(
          (event) =>
            event.type === HttpEventType.UploadProgress ||
            event.type === HttpEventType.Response
        ),
        // prevent passing in events of type upload progress if the total is not valid value
        filter(
          (event) =>
            !(event.type === HttpEventType.UploadProgress && !event.total)
        ),
        map((event) => {
          if (event.type === HttpEventType.UploadProgress) {
            const progress: UploadProgress = {
              progress: Math.round((100 * event.loaded) / event.total),
              state: 'IN_PROGRESS',
            };
            return progress;
          }
          // (event.type === HttpEventType.Response) {
          /* Example Response
          {
              "success": true,
              "url": "./images/1640853699891-711734965-Screenshot (12).png"
          }
        */
          const done: UploadProgress = {
            progress: 100,
            state: 'DONE',
          };
          const res = (event as HttpResponse<object>).body;
          if (res['success']) {
            done.url = this.baseUrl + (res['url'] as string).substring(1);
          }
          return done;
        }),
        catchError(this.handleError)
      );

    return merge(of(initialProgress), makeRequest);
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    console.error({ error });
    // Check for network error
    if (error.status === 0 && error.headers.keys().length === 0) {
      // Possible additional checks
      // error.error instanceof Event && error.error.type === "error" && error.statusText === "Unknown Error"
      // Return an observable with a user-facing error message.
      return throwError(
        'Network error; please check your connection and try again.'
      );
    } else if (error.status === undefined) {
      // A client-side error. Something like parsing or other errors. Handle it accordingly.
      console.error('An client-side error occurred:', error.message);
      // Return an observable with a user-facing error message.
      return throwError('Something bad happened; please try again later.');
    } else if (error.status > 0) {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
      // Return an observable with a user-facing error message.
      return throwError(error.error.message);
    }
    // Return an observable with a user-facing error message.
    return throwError('Something bad happened; please try again later.');
  }
}
