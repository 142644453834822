import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { QuestionBase } from './question-base';

@Injectable()
export class QuestionControlService {
  constructor() {}

  toFormGroup(questions: QuestionBase<string>[]) {
    const group: any = {};

    questions.forEach((question) => {
      group[question.key] = question.required
        ? new FormControl(question.value || '', Validators.required)
        : new FormControl(question.value || '');
    });
    return new FormGroup(group);
  }

  toFormControls(questions: QuestionBase<string>[]) {
    const controls: FormControl[] = [];
    questions.forEach((question) => {
      question.required
        ? controls.push(
            new FormControl(question.value || '', Validators.required)
          )
        : controls.push(new FormControl(question.value || ''));
    });
    return controls;
  }
}
