import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-section-selector',
  templateUrl: './section-selector.component.html',
  styleUrls: ['./section-selector.component.css']
})
export class SectionSelectorComponent implements OnInit {

  sections: {
    [sectionGroup: string]: Section[]
  }
  selectedSection;

  constructor(private window: Window, private dialogRef: MatDialogRef<SectionSelectorComponent, Section>) { }

  ngOnInit(): void {
    if ((<any>this.window).sections) {
      this.sections = (<any>this.window).sections;
      this.selectedSection= Object.keys(this.sections)[0];
    }
  }

  selectSection(section: Section): void {
    this.dialogRef.close(section);
  }

}

type Section = {
  name: string,
  thumbnail: string,
  url: string,
}
