import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FileDisplayComponent } from '../file-display/file-display.component';

import { QuestionBase } from '../shared/question-base';

@Component({
  selector: 'app-question',
  templateUrl: './dynamic-form-question.component.html',
  styleUrls: ['./dynamic-form-question.component.css'],
})
export class DynamicFormQuestionComponent implements OnInit {
  @Input() question!: QuestionBase<string>;
  @Input() form!: FormGroup;
  urls: Set<string> = new Set();
  @ViewChild(FileDisplayComponent) fileDisplay: FileDisplayComponent;

  @Output() uploadFinished: EventEmitter<{ key: string; url: string }> =
    new EventEmitter();

  get isValid() {
    return this.form.controls[this.question.key].valid;
  }

  ngOnInit(): void {
    this.urls.add(this.question.value);
  }

  onFileUploaded(fileUploadEvent: { url: string; index: number }): void {
    this.fileDisplay.urls = new Set<string>([fileUploadEvent.url]);
    this.uploadFinished.emit({
      key: this.question.key,
      url: fileUploadEvent.url,
    });
  }
}
