import { Component, Input, OnInit } from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
  selector: 'app-file-display',
  templateUrl: './file-display.component.html',
  styleUrls: ['./file-display.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: FileDisplayComponent
    }
  ]
})
export class FileDisplayComponent implements OnInit, ControlValueAccessor {

  @Input() width = '50px';
  @Input() height = '50px';
  @Input() urls: Set<string> = new Set();

  constructor() { }

  ngOnInit(): void {}

  addUrl(url: string): void {
    this.urls.add(url);
  }

  registerOnChange(fn: any): void {
  }

  registerOnTouched(fn: any): void {
  }

  writeValue(obj: any): void {
    this.urls = new Set([obj]);
  }
}
