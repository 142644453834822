import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-pill',
  templateUrl: './pill.component.html',
  styleUrls: ['./pill.component.css']
})
export class PillComponent implements OnInit {

  @Input() theme: string;
  @Input() colors: { [key: string]: string } ;
  @Output() colorChanged: EventEmitter<{ [key: string]: string } > = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  changeColor(color: string, key: number): void {
    this.colors[key] = color;
    this.colorChanged.emit(this.colors);
  }

}
